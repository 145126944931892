import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Author from "@material-ui/icons/AccountCircle"
import Date from "@material-ui/icons/Today"
import Time from "@material-ui/icons/Schedule"

const Post = ({ title, author, path, date, body, readTime, fluid, tags }) => {
  return (
    <div id="post">
      <Link to={"/blog/" + path}>
        <Img
          className="featured-image"
          fluid={fluid}
          imgStyle={{ objectFit: "cover" }}
          alt="Featured blog image"
        />
      </Link>
      <div id="post-info-container">
        <div id="post-tags">
          {tags.map(tag => (
            <li id="post-tag" key={tag}>
              <Link
                to={`/blog/topic/${tag.replace(/\s+/g, "-").toLowerCase()}`}
              >
                {tag.replace(/\-+/g, " ")}
              </Link>
            </li>
          ))}
        </div>
      </div>
      <h2>
        {" "}
        <Link to={"/blog/" + path}>{title}</Link>
      </h2>
      <div id="post-info-container">
        <div id="post-info">
          <Author id="post-info-icon" />
          {author}
        </div>
        <div id="post-info">
          <Time id="post-info-icon" /> {readTime} min read
        </div>
      </div>
      <p id="excerpt">{body}</p>
    </div>
  )
}
export default Post
