import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

export default function GetTags() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { tags: { ne: null } } }) {
        edges {
          node {
            frontmatter {
              tags
            }
          }
        }
      }
    }
  `)
  let tagsList = []
  data.allMarkdownRemark.edges.map(({ node }) =>
    tagsList.push(node.frontmatter.tags[0])
  )
  let tags = [...new Set(tagsList)]

  return tags.map(tag => {
    return (
      <Link
        to={"/blog/topic/" + tag.toString().replace(/\s+/g, "-").toLowerCase()}
        key={tag}
      >
        {tag}
      </Link>
    )
  })
}
